import React, { Fragment } from 'react';

import useTeamChanges from './UseTeamChanges';

import Headings from '../../../../common/components/headings/Headings';
import TreeView from "../../../../common/components/tree/TreeView";
import Input from "../../../../common/components/inputs/Input";
import PrimaryButton from "../../../../common/components/buttons/PrimaryButton";
import SecondaryButton from "../../../../common/components/buttons/SecondaryButton";
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';

import Constants from "../../../../common/utils/Constants";

const TeamChanges = () => {
    const {
        isSaving,
        treeData,
        isTreeLoading,
        formState,
        errorState,
        uiState,
        otherOrgUnitChangeHistoryState,
        treeViewLabel,
        onFormValueChanged,
        handleSubmit,
        expireTeam,
        todaysDate,
        isNCAA
    } = useTeamChanges();

    return (
        <Fragment>
            <div className="row">
                <div className="col-xs-12">
                    <Headings.H3>Team Changes</Headings.H3>
                </div>
            </div>
            {new Date(otherOrgUnitChangeHistoryState.objData.expirationDate) > new Date(todaysDate) ?
            <form onSubmit={handleSubmit} noValidate>
                {uiState.wasDeleted &&
                <div className="row">
                    <div className="col-xs-12 col-sm-6 col-md-4">
                        <span style={{fontSize: 24, color: 'darkgray', marginTop: 10, marginBottom: 10}}>Team has been expired</span>
                    </div>
                </div>
                }
                <div className="row">
                    <div className="col-xs-12 col-sm-6 col-md-4">
                        <TreeView
                            label={treeViewLabel}
                            name="orgUnit"
                            value={formState.orgUnit}
                            options={treeData}
                            selectableLeavesOnly={false}
                            singleSelect={true}
                            error={errorState.orgUnit}
                            message={errorState.orgUnit}
                            isLoading={isTreeLoading}
                            onChange={(newValue) => { onFormValueChanged('orgUnit', newValue) }} />
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-4">
                        <Input
                            label={"Team Name*"}
                            name={"teamName"}
                            value={formState.teamOrgUnitName}
                            error={errorState.teamOrgUnitName}
                            message={errorState.teamOrgUnitName}
                            onChange={(newValue) => { onFormValueChanged('teamOrgUnitName', newValue) }} />
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-4">
                        <Input
                            label={"Team Code*"}
                            name={"teamCode"}
                            value={formState.teamOrgUnitCode}
                            error={errorState.teamOrgUnitCode}
                            message={errorState.teamOrgUnitCode}
                            onChange={(newValue) => { onFormValueChanged('teamOrgUnitCode', newValue) }} />
                    </div>
                </div>
                <div className="row usas-extra-top-margin usas-extra-bottom-margin">
                    <div className="col-xs-12 usas-extra-top-margin">
                        <PrimaryButton type="submit">Save</PrimaryButton>&nbsp;
                        {isNCAA === true && new Date(otherOrgUnitChangeHistoryState.objData.expirationDate) > new Date(todaysDate) &&
                        <SecondaryButton type="button" onClick={expireTeam}>Expire Team</SecondaryButton>
                        }
                    </div>
                </div>
            </form>
            : <div className="row">
                <div className="col-xs-12 col-sm-6 col-md-4">
                    <span style={{fontSize: 24, color: 'darkgray', marginTop: 10, marginBottom: 10}}>
                        Team is expired
                    </span>
                </div>
            </div>
            }
            <PopUpModal
                widthPct={50}
                maxWidth={250}
                title={Constants.SAVING_MSG}
                displayPopUp={isSaving} />
        </Fragment>);
};
export default TeamChanges;