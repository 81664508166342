import { useEffect, useState } from 'react';
import { useNavigate } from '../../../../../common/wrappers/ReactRouterDom';

import NavLinks from '../../NavLinks';

import useTeamData from '../../../../state/team/UseTeamData';

import useReportPeriodData from '../../../../../common/state/reportPeriod/UseReportPeriodData';
import useOrgUnitAthleteData from '../../../../../common/state/orgUnitAthlete/UseOrgUnitAthleteData';
import useReportPeriodFlatData from '../../../../../common/state/reportPeriodFlat/UseReportPeriodFlatData';
import useEnvironmentVariableData from '../../../../../common/state/environmentVariable/UseEnvironmentVariableData';

import Constants from '../../../../../common/utils/Constants';
import { formatDate } from '../../../../../common/utils/DateFunctions';

const useTeamAthleteAdvancedWriteRoster = () => {
  const navigate = useNavigate();
  const [state, setState] = useState({ isNCAA: false, isISL: false, isCSS: false });
  const [filterState, setFilterState] = useState({ reportPeriod: [] });
  const [gridState, setGridState] = useState([]);
  const [exportState, setExportState] = useState([]);
  const [modalState, setModalState] = useState(getInitialModalState());
  const { teamState } = useTeamData();
  const { orgUnitAthleteState, getOrgUnitAthletePiiSafe, deleteOrgUnitAthlete, resetOrgUnitAthleteState, postOrgUnitAthleteRosterRollover } = useOrgUnitAthleteData();
  const { reportPeriodState } = useReportPeriodData();
  const { reportPeriodFlatState, getReportPeriodsFlatByOrganizationId } = useReportPeriodFlatData();
  const { environmentVariableState, NCAA_ORGANIZATION_ID, CSS_ORGANIZATION_ID, NCAA_SEASON_START_MONTH_DAY, ISL_ORGANIZATION_ID } = useEnvironmentVariableData();
  const [rolloverState, setRolloverState] = useState({ rolloverPersons: [] });

  const onRolloverRoster = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    postOrgUnitAthleteRosterRollover(rolloverState.rolloverPersons);
  }

  const onAdd = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    navigate(NavLinks.TEAM_ATHLETE_ROSTER_ADVANCED_DETAIL, { state: { personId: '' } });
  };

  const onEdit = (e, person, personId) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    };

    navigate(NavLinks.TEAM_ATHLETE_ROSTER_ADVANCED_DETAIL, { state: { person: person, personId: personId } });
  };

  const onDelete = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    deleteOrgUnitAthlete(modalState.personOrgRoleDurationId);
    setModalState({
      ...getInitialModalState()
    });
  };

  const onOpenModalClicked = (e, athlete) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    const athleteInfo = `${athlete.firstName} ${athlete.preferredName !== '' ?
      '(' + athlete.preferredName + ')' : ''} ${athlete.middleName} ${athlete.lastName} 
    (Age: ${athlete.age}, Competition Category: ${athlete.competitionGenderTypeName}, 
      Effective Date: ${formatDate(athlete.effectiveDate)}, 
      Expiration Date: ${formatDate(athlete.expirationDate)})`;
    setModalState({
      ...modalState,
      personOrgRoleDurationId: athlete.personOrgRoleDurationId,
      athleteInfo: athleteInfo,
      displayPopUp: true
    });
  };

  const onModalCanceled = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    setModalState(getInitialModalState());
  };

  const onRolloverCheckbox = (e, personId, personOrgRoleDurationId) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    let personArray = rolloverState.rolloverPersons;

    const idx = personArray.findIndex(x => x.personId === personId);

    if (idx > -1) {
      let removedItem = personArray.splice(idx, 1);
    } else {
      const arrayIdx = reportPeriodState.arrayData.length - 1;
      const personObj = {
        orgUnitId: teamState.objData?.orgUnitId || Constants.DEFAULT_ID,
        personId: personId,
        personOrgRoleDurationId: personOrgRoleDurationId,
        reportPeriodId: reportPeriodState.arrayData[arrayIdx].reportPeriodId //filterState.reportPeriod[0].id || Constants.DEFAULT_ID
      }

      personArray.push(personObj);
    }

    setRolloverState({
      ...rolloverState,
      rolloverPersons: personArray
    });
  }

  useEffect(() => {
    //Get flat report periods to filter by date easier
    if (Object.keys(teamState.objData).length > 0 && reportPeriodFlatState.isLoading !== true
      && teamState.objData?.orgLevel?.organizationId > 0
      && teamState.objData?.orgLevel?.organizationId !== reportPeriodFlatState.organizationId) {
      getReportPeriodsFlatByOrganizationId(teamState.objData.orgLevel.organizationId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps   
  }, [teamState.objData, reportPeriodFlatState]);

  useEffect(() => {
    if (reportPeriodState.isArrayLoading === false && reportPeriodState.isArrayLoaded === true
      && environmentVariableState.isLoaded === true) {
      const today = new Date();
      const month = today.getMonth();
      const year = today.getFullYear().toString();
      let todayFormatted;
      //Handle April (3) through August (7) gap for NCAA seasons
      if (state.isNCAA === true && month >= 3 && month <= 7) {
        const startDate = year + '/' + NCAA_SEASON_START_MONTH_DAY;
        todayFormatted = new Date(formatDate(new Date(startDate)));
      }
      else {
        todayFormatted = new Date(formatDate(today));
      }

      const currentReportPeriods = [];
      const futureReportPeriods = [];
      let treeArrayDataIndex = [];
      let startDate;
      let endDate;
      for (let i = 0; i < reportPeriodState.arrayData.length; i++) {
        const selectedArrayData = reportPeriodState.arrayData[i];
        if (state.isNCAA === true || state.isISL === true || state.isCSS === true) {
          startDate = new Date(formatDate(selectedArrayData.StartDate));
          endDate = new Date(formatDate(selectedArrayData.EndDate));
          if (todayFormatted <= endDate && todayFormatted >= startDate) {
            currentReportPeriods.push(selectedArrayData);
          }
          if (todayFormatted < startDate) {
            futureReportPeriods.push(selectedArrayData);
          }
        }
        else {
          if (selectedArrayData.children) {
            for (let i = 0; i < selectedArrayData.children.length; i++) {
              startDate = new Date(formatDate(selectedArrayData.children[i].StartDate));
              endDate = new Date(formatDate(selectedArrayData.children[i].EndDate));
              if (todayFormatted <= endDate && todayFormatted >= startDate) {
                currentReportPeriods.push(selectedArrayData.children[i]);
                const index = reportPeriodState.arrayData.findIndex(x => x.id === selectedArrayData.id);
                treeArrayDataIndex.push(index);
              }
              if (todayFormatted < startDate) {
                futureReportPeriods.push(selectedArrayData.children[i]);
              }
            }
          }
        }
      }

      const treeArrayData = JSON.parse(JSON.stringify(reportPeriodState.treeData));
      if (currentReportPeriods.length > 0) {
        if (state.isNCAA === true || state.isISL === true || state.isCSS === true) {
          const currentOption = treeArrayData.find(x => x.id === currentReportPeriods[0].reportPeriodId);
          const currentOptionFormatted = [{ id: currentOption.id, name: currentOption.name }];
          setFilterState({ ...filterState, reportPeriod: currentOptionFormatted });
        }
        else {
          const currentOption = treeArrayData[treeArrayDataIndex[0]].children.find(x => x.id === currentReportPeriods[0].reportPeriodId);
          const currentOptionFormatted = [{ id: currentOption.id, name: currentOption.name }];
          setFilterState({ ...filterState, reportPeriod: currentOptionFormatted });
        }
      }
      else if (futureReportPeriods.length > 0 && (state.isNCAA === true || state.isISL === true || state.isCSS === true)) {
        const futureOption = treeArrayData.find(x => x.id === futureReportPeriods[0].reportPeriodId);
        const futureOptionFormatted = [{ id: futureOption.id, name: futureOption.name }];
        setFilterState({ ...filterState, reportPeriod: futureOptionFormatted });
      }
      else {
        if (state.isNCAA === true || state.isISL === true || state.isCSS === true) {
          const currentOption = treeArrayData[treeArrayData.length - 1];
          const currentOptionFormatted = [{ id: currentOption.id, name: currentOption.name }];
          setFilterState({ ...filterState, reportPeriod: currentOptionFormatted });
        }
        else if (treeArrayData.length > 0) {
          const currentOption = treeArrayData[treeArrayData.length - 1];
          const currentOptionFormatted = [{ id: currentOption.id, name: currentOption.name }];
          setFilterState({ ...filterState, reportPeriod: currentOptionFormatted });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps   
  }, [reportPeriodState, state.isNCAA, state.isISL, state.isCSS, environmentVariableState]);

  useEffect(() => {
    if (orgUnitAthleteState.isArrayLoading === false && orgUnitAthleteState.isArrayLoaded === true &&
      reportPeriodFlatState.isLoading === false && reportPeriodFlatState.isLoaded === true) {
      if (filterState.reportPeriod.length > 0) {
        const filter = reportPeriodFlatState.data.find(x => x.reportPeriodId === filterState.reportPeriod[0].id);
        const filterStartDate = new Date(formatDate(filter?.startDate));
        const filterEndDate = new Date(formatDate(filter?.endDate));
        const teamAthleteArrayData = JSON.parse(JSON.stringify(orgUnitAthleteState.arrayData));
        const filteredTeamAthleteArrayData = [];
        for (let i = 0; i < teamAthleteArrayData.length; i++) {
          const effectiveDate = new Date(formatDate(teamAthleteArrayData[i].effectiveDate));
          const expirationDate = new Date(formatDate(teamAthleteArrayData[i].expirationDate));
          //Show in grid if athlete association spanned the time period or longer
          if (effectiveDate <= filterStartDate && expirationDate >= filterEndDate) {
            filteredTeamAthleteArrayData.push(teamAthleteArrayData[i]);
          }
          //Show in grid if athlete association started during report period
          else if (effectiveDate >= filterStartDate && effectiveDate <= filterEndDate) {
            filteredTeamAthleteArrayData.push(teamAthleteArrayData[i]);
          }
          //Show in grid if athlete association ended during report period
          else if (expirationDate >= filterStartDate && expirationDate <= filterEndDate) {
            filteredTeamAthleteArrayData.push(teamAthleteArrayData[i]);
          }
        }
        setGridState(filteredTeamAthleteArrayData);

        let currentAthletes = [];
        const today = new Date();
        filteredTeamAthleteArrayData.forEach(athlete => {
          const expirationDate = new Date(formatDate(athlete.expirationDate));

          if (expirationDate > today) {
            currentAthletes.push(athlete);
          }
        })

        setExportState(currentAthletes);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps   
  }, [orgUnitAthleteState, reportPeriodFlatState, filterState.reportPeriod]);

  useEffect(() => {
    if (environmentVariableState.isLoaded === true && Object.keys(teamState.objData).length > 0) {
      getOrgUnitAthletePiiSafe(teamState.objData?.orgUnitId);
      if (teamState.objData?.orgLevel?.organizationId === NCAA_ORGANIZATION_ID) {
        setState({ ...state, isNCAA: true, isISL: false, isCSS: false });
      }
      else if (teamState.objData?.orgLevel?.organizationId === ISL_ORGANIZATION_ID) {
        setState({ ...state, isNCAA: false, isISL: true, isCSS: false });
      }
      else if (teamState.objData?.orgLevel?.organizationId === CSS_ORGANIZATION_ID) {
        setState({ ...state, isNCAA: false, isISL: false, isCSS: true });
      }
      else {
        setState({ ...state, isNCAA: false, isISL: false, isCSS: false });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps   
  }, [environmentVariableState, teamState.objData]);

  useEffect(() => {
    if (orgUnitAthleteState.isSaving === false && orgUnitAthleteState.isSaved === true) {
      setRolloverState({
        ...rolloverState,
        rolloverPersons: []
      });

      resetOrgUnitAthleteState();
    } else if (orgUnitAthleteState.isArrayLoading === false && orgUnitAthleteState.isArrayLoaded === false) {
      getOrgUnitAthletePiiSafe(teamState.objData.orgUnitId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgUnitAthleteState]);

  function canRollover() {
    if (teamState.objData.organization.organizationCode === 'NCAA' && reportPeriodState.isArrayLoaded === true && filterState.reportPeriod.length > 0) {
      const mostRecentReportPeriodYear = reportPeriodState.arrayData[reportPeriodState.arrayData.length - 1]?.StartDate.slice(0, 4);
      const selectedReportPeriodYear = filterState.reportPeriod[0]?.name.slice(0, 4);
      const isSelectedReportPeriodTheMostRecentPreviousPeriod = mostRecentReportPeriodYear - selectedReportPeriodYear === 1;

      return (reportPeriodState.arrayData[reportPeriodState.arrayData.length - 1]?.reportPeriodId > filterState.reportPeriod[0]?.id) &&
        isSelectedReportPeriodTheMostRecentPreviousPeriod || false;
    }
    return false;
  }

  return {
    rolloverState,
    state,
    reportPeriodFlatState,
    filterState,
    setFilterState,
    teamState,
    orgUnitAthleteState,
    gridState,
    exportState,
    modalState,
    Constants,
    onAdd,
    onEdit,
    onDelete,
    onOpenModalClicked,
    onModalCanceled,
    onRolloverCheckbox,
    onRolloverRoster,
    canRollover: canRollover()
  };
};

export default useTeamAthleteAdvancedWriteRoster;

function getInitialModalState() {
  return {
    displayPopUp: false,
    personOrgRoleDurationId: '',
    athleteInfo: '',
    modalTitle: 'Make Athlete Inactive?'
  };
};