import { useState } from 'react';

import OtherOrgUnitExpireData from './OtherOrgUnitExpireData';

import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

const INITIAL_OTHER_ORG_UNIT_EXPIRE_STATE = {
  ...BASIC_INITIAL_STATE
};

const useOtherOrgUnitExpireData = () => {
  const [otherOrgUnitExpireState, setOtherOrgUnitExpireState] = useState(INITIAL_OTHER_ORG_UNIT_EXPIRE_STATE);

  const putOtherOrgUnitExpire = (orgUnitId) => {
    return OtherOrgUnitExpireData.putOtherOrgUnitExpireData(orgUnitId, otherOrgUnitExpireState, setOtherOrgUnitExpireState);
  };

  return {
    otherOrgUnitExpireState,
    putOtherOrgUnitExpire
  };
}

export default useOtherOrgUnitExpireData;