import React, { Fragment } from 'react';

import useTeamContextView from './UseTeamContextView';
import NavLinks from './NavLinks';

import Headings from '../../../common/components/headings/Headings';
import LeftArrowIcon from '../../../common/components/icons/LeftArrowIcon';
import MobileContextBasedNav from '../../../common/components/contextBasedNav/MobileContextBasedNav';
import LargeContextBasedNav from '../../../common/components/contextBasedNav/LargeContextBasedNav';
import PageNotFound from '../../../common/components/PageNotFound';
import PopUpModal from '../../../common/components/dialogs/PopUpModal';

import Constants from '../../../common/utils/Constants';
import global from '../../../common/components/GlobalStyle.module.css';
import { formatDate } from '../../../common/utils/DateFunctions';

const TeamContextView = ({ children }) => {
  const {
    location,
    teamState,
    otherOrgUnitChangeHistoryState,
    backToLabel,
    selectOrgUnitState,
    onBackClicked,
    onHomeClicked,
    onBackToSelectionClicked
  } = useTeamContextView();

  if (!teamState || (otherOrgUnitChangeHistoryState.isObjLoaded === false && teamState.isObjLoaded === false && location.pathname !== '/team/facility')) {
    return teamState.isObjLoading === false ?
      <Fragment>
        <div className="row usas-extra-bottom-margin">
          <div className="col-xs-12">
            {selectOrgUnitState.orgUnitId !== '' ?
              <button className={global.BackToSearchButton}
                type="button"
                onClick={onBackToSelectionClicked}>
                <LeftArrowIcon />  Back to Selection
              </button> :
              <button className={global.BackToSearchButton}
                type="button"
                onClick={onBackClicked}>
                <LeftArrowIcon />  {backToLabel}
              </button>}
          </div>
        </div>
        <div className="row usas-extra-bottom-margin">
          <div className="col-xs-12">
            <PageNotFound />
          </div>
        </div>
      </Fragment>
      : (
        <div className="row usas-extra-bottom-margin">
          <div className="col-xs-12">
            <PopUpModal
              widthPct={50}
              maxWidth={250}
              title={Constants.LOADING_MSG}
              displayPopUp={true} />
          </div>
        </div>
      );
  }

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <button className={global.HeaderButton} onClick={onHomeClicked}>
            <Headings.H3
              className={[global.NoBottomMargin, 'col-xs-12'].join(' ')}
              excludeBar={true}>
              View/Edit Team
            </Headings.H3>
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          <hr className="usas-bar-turmeric" />
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          {selectOrgUnitState.orgUnitId !== '' ?
            <Fragment>
              {selectOrgUnitState.showBackToSelection &&
                <button className={global.BackToSearchButton}
                  type="button"
                  onClick={onBackToSelectionClicked}>
                  <LeftArrowIcon />  Back to Selection
                </button>}</Fragment> :
            <button className={global.BackToSearchButton}
              type="button"
              onClick={onBackClicked}>
              <LeftArrowIcon />  {backToLabel}
            </button>}
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 col-sm-12">
          <p className={global.HeaderText}><b>Team Name:</b>&nbsp;{teamState.objData.orgUnitName || ''}</p>
          <p className={global.HeaderText}><b>Team Code:</b>&nbsp;{teamState.objData.orgUnitCode || ''}</p>
          <p className={global.HeaderText}><b>Organization:</b>&nbsp;{teamState.objData.organization?.organizationName || ''} - {teamState.objData.parentOrgUnitCode || ''}</p>
          <p className={global.HeaderText}><b>Expiration Date:</b>&nbsp;{formatDate(otherOrgUnitChangeHistoryState.objData.expirationDate) || ''}</p>
        </div>
      </div>
      <div className="row">
        <div className="visible-xs col-xs-12">
          <MobileContextBasedNav taxonomyName="OtherTeamDetail" renderPillsOnly={true} />
        </div>
        <div className="hidden-xs col-xs-12">
          <LargeContextBasedNav taxonomyName="OtherTeamDetail" homeRoute={NavLinks.TEAM_STAFF} renderPillsOnly={true} />
        </div>
      </div>
      {children}
    </Fragment>
  );
};

export default TeamContextView;