import { API_NAMES, initApi } from "../../../common/utils/HttpApiHelper";

const getOtherOrgUnitChangeHistoryData = (orgUnitId, state, setState) => {
  if (orgUnitId) {
    const api = initApi(API_NAMES.COMMON, state, setState);
    const orgUnitIdForUrl = encodeURIComponent(orgUnitId);
    const url = `/OrgUnit/ChangeHistory/${orgUnitIdForUrl}`;

    return api?.executeObject ? api.executeObject(url, 'GET') : null;
  }
};

const OtherOrgUnitChangeHistoryData = {
  getOtherOrgUnitChangeHistoryData
};

export default OtherOrgUnitChangeHistoryData;