import { useEffect } from 'react';
import { useNavigate, useLocation } from '../../../common/wrappers/ReactRouterDom';

import NavLinks from './NavLinks';

import useTeamData from '../../state/team/UseTeamData';
import useOtherOrgUnitChangeHistoryData from '../../state/otherOrgUnitChangeHistory/UseOtherOrgUnitChangeHistoryData';

import useTeamFacilityLinksData from '../../state/teamFacilityLinks/UseTeamsFacilityLinksData';

import usePersonOrgRoleData from '../../../common/state/personOrgRole/UsePersonOrgRoleData';
import useSelectOrgUnitData from '../../../common/state/selectOrgUnit/UseSelectOrgUnitData';

const BACK_TO_LABELS = {
  teamBackToLabel: 'Back to Team Search'
};

const useTeamContextView = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { teamState, clearObjData } = useTeamData();
  const { otherOrgUnitChangeHistoryState, getOtherOrgUnitChangeHistory } = useOtherOrgUnitChangeHistoryData();

  const { clearArrayData } = usePersonOrgRoleData();
  const { selectOrgUnitState, resetSelectOrgUnitState } = useSelectOrgUnitData();
  const { clearTeamFacilityLinksArrayData } = useTeamFacilityLinksData();

  const onBackClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    goToTeamSearch();
  };

  const onHomeClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    navigate(NavLinks.TEAM_STAFF);
  };

  const onBackToSelectionClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    resetSelectOrgUnitState();
    clearArrayData();
    clearObjData();
    clearTeamFacilityLinksArrayData();
    navigate(NavLinks.TEAM_SELECTION);
  };

  function goToTeamSearch() {
    clearObjData();
    clearTeamFacilityLinksArrayData();
    navigate(NavLinks.TEAM_SEARCH);
  };

  function getBackToLabel() {
    return BACK_TO_LABELS.teamBackToLabel;
  };

  useEffect(() => {
    if (teamState.isObjLoaded === true) {
      const getOtherOrgUnitChangeHistoryPromise = getOtherOrgUnitChangeHistory(teamState.objData.orgUnitId);

      if (getOtherOrgUnitChangeHistoryPromise ?? false) {
        getOtherOrgUnitChangeHistoryPromise.catch((e) => {
          //context error
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    location,
    teamState,
    otherOrgUnitChangeHistoryState,
    backToLabel: getBackToLabel(),
    selectOrgUnitState,
    onBackClicked,
    onHomeClicked,
    onBackToSelectionClicked
  };
};

export default useTeamContextView;