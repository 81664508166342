import { isValidName, isValidDate, isValidBirthDate } from '../../../../common/utils/validation';
import { getAge } from '../../../../common/utils/DateFunctions';
import Constants from '../../../../common/utils/Constants';

export const localValidate = (formState) => {
  let errors = {};

  //Edit NCAA Athlete validation
  if (formState.personId) {
    if (formState.yearOfEligibilityId < 0 || formState.yearOfEligibilityName === '') {
      errors.yearOfEligibilityId = 'Year of Eligibility is required';
    }
  }
  //Add Athlete validation
  else {
    if (formState.firstName.trim() === '') {
      errors.firstName = 'First Name is required';
    } else if (!isValidName(formState.firstName.trim())) {
      errors.firstName = 'First Name cannot contain numbers, special characters, or exceed 100 characters';
    }

    if (formState.lastName.trim() === '') {
      errors.lastName = 'Last Name is required';
    } else if (!isValidName(formState.lastName.trim())) {
      errors.lastName = 'Last Name cannot contain numbers, special characters, or exceed 100 characters';
    }

    if (formState.birthDate === Constants.BLANK_DATE_STRING) {
      errors.birthDate = 'Birth Date is required';
    } else if (!isValidDate(formState.birthDate)) {
      errors.birthDate = 'Birth Date must be a valid date';
    } else if (!isValidBirthDate(formState.birthDate)) {
      errors.birthDate = 'Birth Date cannot be in the future';
    }

    if (formState.competitionGenderTypeId === '') {
      errors.competitionGenderTypeId = 'Competition Category is required';
    }

    //Validate Year of Eligibility and NCAA Sport for NCAA only
    if (formState.isNcaa === true) {
      if (formState.yearOfEligibilityId < 0 || formState.yearOfEligibilityName === '') {
        errors.yearOfEligibilityId = 'Year of Eligibility is required';
      }

      if (formState.ncaaOrgRoleId === Constants.DEFAULT_ID) {
        errors.ncaaOrgRoleId = 'NCAA Sport is required';
      }

      if (getAge(formState.birthDate) > 26 || getAge(formState.birthDate) < 16) {
        errors.birthDate = 'Athlete must be between 16 and 26 years old'
      }
    }
  }

  return errors;
};

const TeamAthleteDetailValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};

export default TeamAthleteDetailValidation;