import { useEffect, useState } from 'react';

import validate from './TeamChangesValidation';

import useForm from '../../../../common/utils/UseForm';
import { DEFAULT_ID } from '../../../../common/utils/Constants';
import HierarchicalDataUtils from '../../../../common/utils/HierarchicalDataUtils';

import useTeamData from '../../../state/team/UseTeamData';
import useOtherOrgOrgUnitTopLevelsData from '../../../state/otherOrgOrgUnitTopLevels/UseOtherOrgOrgUnitTopLevelsData';
import useOtherOrgOrgUnitChangesData from '../../../state/otherOrgOrgUnitChanges/UseOtherOrgOrgUnitChangeData';
import useOtherOrgUnitExpireData from '../../../state/otherOrgUnitExpire/UseOtherOrgUnitExpireData';
import useOtherOrgUnitChangeHistoryData from '../../../state/otherOrgUnitChangeHistory/UseOtherOrgUnitChangeHistoryData';

const useTeamChanges = () => {
    const { teamState } = useTeamData();
    const { otherOrgOrgUnitTopLevelsState, getOtherOrgOrgUnitTopLevels } = useOtherOrgOrgUnitTopLevelsData();
    const { otherOrgOrgUnitChangeState, putOtherOrgOrgUnitChange } = useOtherOrgOrgUnitChangesData();
    const { otherOrgUnitExpireState, putOtherOrgUnitExpire } = useOtherOrgUnitExpireData();
    const { otherOrgUnitChangeHistoryState } = useOtherOrgUnitChangeHistoryData();

    const [uiState, setUiState] = useState({ wasDeleted: false });

    const { formState, errorState, setFormState, onFormValueChanged, handleSubmit } = useForm(initialState, submitFormCallback, validate);

    function createOrgUnitObj() {
        return {
            parentOrgUnitId: formState.orgUnit.length > 0 ? formState.orgUnit[0].id : undefined,
            orgUnitName: formState.teamOrgUnitName,
            orgUnitCode: formState.teamOrgUnitCode
        };
    };

    function submitFormCallback() {
        putOtherOrgOrgUnitChange(teamState.objData.orgUnitId, createOrgUnitObj());
    };

    const expireTeam = () => {
        const putputOtherOrgUnitExpirePromise = putOtherOrgUnitExpire(teamState.objData.orgUnitId);

        if (putputOtherOrgUnitExpirePromise ?? false) {
            putputOtherOrgUnitExpirePromise.then((newState) => {
                if (newState ?? false) {
                    if (newState.objData?.status === "True") {
                        setUiState({ wasDeleted: true });
                    }
                }
            }).catch((e) => {
                //state error
                console.log('error occured', e);
            });
        }
    }

    useEffect(() => {
        if (otherOrgOrgUnitTopLevelsState.isArrayLoaded === true) {
            setFormState({
                ...formState,
                orgUnitId: teamState.objData.parentOrgUnitId,
                orgUnit: HierarchicalDataUtils.GetNameIdPairs(otherOrgOrgUnitTopLevelsState.treeData, [teamState.objData.parentOrgUnitId]) || [],
                teamOrgUnitCode: teamState.objData.orgUnitCode,
                teamOrgUnitName: teamState.objData.orgUnitName
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [otherOrgOrgUnitTopLevelsState.isArrayLoaded]);

    useEffect(() => {
        if (teamState.isObjLoaded === true) {
            getOtherOrgOrgUnitTopLevels();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function initialState() {
        return {
            orgUnitId: DEFAULT_ID,
            orgUnit: [],
            teamOrgUnitId: '',
            teamOrgUnitName: '',
            teamOrgUnitCode: '',
            parentOrgUnitId: DEFAULT_ID,
            parentOrgUnitName: ''
        };
    };

    const getDate = () => {
        const date = new Date();
        return date.toLocaleString().split(",")[0];
    }

    return {
        isSaving: otherOrgOrgUnitChangeState !== undefined ? otherOrgOrgUnitChangeState.isSaving : false,
        treeData: otherOrgOrgUnitTopLevelsState.isArrayLoaded ?
            otherOrgOrgUnitTopLevelsState.treeData.filter(x => x.name === teamState.objData.organization.organizationName) : [],
        isTreeLoading: otherOrgOrgUnitTopLevelsState.isArrayLoading === true,
        formState,
        errorState,
        uiState,
        otherOrgUnitExpireState,
        otherOrgUnitChangeHistoryState,
        treeViewLabel: teamState.objData.organization.organizationId === 2 ? "Country"
            : teamState.objData.organization.organizationId === 3 ? "Conference"
                : teamState.objData.organization.organizationId === 6 ? "Team" : "Organization",
        onFormValueChanged,
        handleSubmit,
        expireTeam,
        todaysDate: getDate(),
        isNCAA: teamState.objData?.organization?.organizationCode === "NCAA"
    };
};

export default useTeamChanges;