import { useContext } from 'react';

import OtherOrgUnitChangeHistoryData from './OtherOrgUnitChangeHistoryData';

import { OtherOrgUnitChangeHistoryStateContext } from './OtherOrgUnitChangeHistoryContextProvider';

const useOtherOrgUnitChangeHistoryData = () => {
  const [otherOrgUnitChangeHistoryState, setOtherOrgUnitChangeHistoryState] = useContext(OtherOrgUnitChangeHistoryStateContext);
  
  const getOtherOrgUnitChangeHistory = (orgUnitId) => {
    return OtherOrgUnitChangeHistoryData.getOtherOrgUnitChangeHistoryData(orgUnitId, otherOrgUnitChangeHistoryState, setOtherOrgUnitChangeHistoryState);
  };

  return {
    otherOrgUnitChangeHistoryState,
    setOtherOrgUnitChangeHistoryState,
    getOtherOrgUnitChangeHistory
  };
}

export default useOtherOrgUnitChangeHistoryData;