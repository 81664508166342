import { API_NAMES, initApi } from "../../../common/utils/HttpApiHelper";

const putOtherOrgUnitExpireData = (orgUnitId, state, setState) => {
  const api = initApi(API_NAMES.COMMON, state, setState);
  const url = `/OrgUnit/${orgUnitId}/OtherOrganization/ExpireTeam`;

  return api?.executeObject ? api.executeObject(url, 'PUT', orgUnitId) : null;
};

const OtherOrgUnitExpireData = {
  putOtherOrgUnitExpireData
};

export default OtherOrgUnitExpireData;